<template>
  <!-- dialog:products START -->
  <el-dialog
    class="without-wrapper el-dialog__wrapper-products"
    :visible.sync="dialogVisible"
    :before-close="dialogClose"
    :fullscreen="true"
    :append-to-body="true"
    width="80%"
  >

    <!-- dialog:products:info START -->
    <span slot="title" v-if="title" v-html="title"></span>
    <h2 v-if="headline" v-html="headline"></h2>
    <div class="description" v-if="description" v-html="description"></div>
    <!-- dialog:products:info END -->

    <!-- dialog:products:products START -->
    <div class="dialog-products">

      <!-- dialog:products:products:product START -->
      <div
        :class="[
          'dialog-product',
          { 'is-selected' : products.items.some((p) => p.articleData.id === product.id) },
          { 'pressed' : pressed === productIndex }
        ]"
        v-for="(product, productIndex) in filteredProducts"
        :key="`products-product-${productIndex}`"
        v-on:touchstart="touchStart($event, productIndex)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >
        <div
          class="inner"
          v-on:click="activateNumpad(product)"
        >
          <!-- product:image START -->
          <div
            class="image"
          >
            <img
              v-if="product.images.filter(image => image.includes('500x500'))"
              :src="product.images.filter(image => image.includes('500x500'))"
              :alt="product.name"
            />
            <img
              v-else
              :src="product.images[0]"
              :alt="product.name"
            />
            <span
              class="f-like-image-loading-product"
              v-if="!product.images"
            ></span>
          </div>
          <!-- product:image END -->

          <!-- product:headline START -->
          <h2 v-html="product.shortName"></h2>
          <!-- product:headline END -->
        </div>

        <!-- product:selected-message START -->
        <transition name="step-fade" mode="out-in">
          <div
            class="selected-message"
            v-if="products.items.some((p) => p.articleData.id === product.id)"
          >
            <span v-html="$t('terminal.cart.cart.articlescart')"></span>
          </div>
        </transition>
        <!-- product:selected-message START -->

        <!-- product:delete START -->
        <transition name="step-fade" mode="out-in">
          <div
            class="dialog-remove"
            v-if="products.items.some((p) => p.articleData.id === product.id)"
            v-on:click="removeProduct(product.id)"
          >
            <span v-html="$t('terminal.cart.buttons.deletelong')"></span>
          </div>
        </transition>
        <!-- product:delete END -->
      </div>
      <!-- dialog:products:products:product END -->

      <!-- dialog:back-button START -->
      <div class="divider"></div>
      <div
        class="btn-default btn-back"
        v-html="$t('terminal.cart.buttons.backcart')"
        v-on:click="dialogClose()"
        v-on:touchstart="touchStart($event);"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      ></div>
      <!-- dialog:back-button END -->

    </div>
    <!-- dialog:products:products END -->

    <dialog-numpad-quantity
      :dialogNumpadVisible="activeNumpad"
      :product="activeProduct"
      :addProductIDQuantity="addProductIDQuantity"
      :closeNumpad="closeNumpad"
    />
  </el-dialog>
  <!-- dialog:products END -->
</template>

<script>
export default {
  name: 'dialog-products',
  props: {
    title: {
      type: String,
    },

    headline: {
      type: String,
    },

    description: {
      type: String,
    },

    dialogVisible: {
      type: Boolean,
      default: false,
    },

    dialogClose: {
      type: Function,
    },

    type: {
      type: String,
    },

    products: {
      type: Object,
    },

    filteredProducts: {
      type: Array,
    },

    addProductIDQuantity: {
      type: Function,
    },

    removeProduct: {
      type: Function,
    },
  },
  components: {
    DialogNumpadQuantity: () => import('./NumpadQuantity.vue'),
  },
  data() {
    return {
      productQuantity: '',
      activeNumpad: false,
      activeProduct: null,
      pressed: -1,
    };
  },
  methods: {
    activateNumpad(p) {
      this.activeNumpad = true;
      this.activeProduct = p;
    },

    closeNumpad(q) {
      this.addProductIDQuantity(
        this.activeProduct.id,
        Number(q),
      );
      setTimeout(() => {
        this.productQuantity = '';
        this.activeNumpad = false;
        this.activeProduct = null;
      }, 150);
    },

    touchStart(event, i) {
      event.target.classList.add('pressed');
      this.pressed = i;
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
      this.pressed = -1;
    },
  },
  mounted() {
    this.dialogClose();
  },
};
</script>
